import styled from 'styled-components'

export const Container = styled.div``

export const ProductDescription = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 30px 60px;

  @media (max-width: 859px) {
    flex-direction: column;
  }

  img {
    height: 120px;
    margin-right: 60px;

    @media (max-width: 859px) {
      margin-right: 0;
      margin-bottom: 60px;
    }
  }

  p {
    max-width: 700px;
    font-size: 27px;

    @media (max-width: 859px) {
      max-width: 90%;
      text-align: center;
    }
  }
`
