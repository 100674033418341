import React from 'react'

import Hero from 'src/components/Home/Sections/Hero'
import Cards from 'src/components/Products/Cards'

import { Container, ProductDescription } from 'src/styles/produtos'

import curvesYellow from 'src/assets/img/Hero/Curves-Yellow.png'
import curvesMobileYellow from 'src/assets/img/Hero/CurvesMobile-Yellow.png'
import curvesWideYellow from 'src/assets/img/Hero/CurvesWide-Yellow.png'

import tagnosLogo from 'src/assets/img/Products/tagnos_logo.png'

export default function Home() {
  return (
    <Container>
      <Hero
        title="Gerenciamento Escolar"
        subtitle="com eficácia e abrangência"
        text={[
          'A ferramenta ',
          <strong>TAGNOS EDUCAÇÃO</strong>,
          ' é uma das plataformas mais completas do mercado, que permite o gerenciamento de todos os setores da educação até a aplicação didática com alunos.'
        ]}
        bgCurves={curvesYellow}
        bgCurvesMobile={curvesMobileYellow}
        bgCurvesWide={curvesWideYellow}
        textColor="#222"
        chevronColor="#222"
      />

      <ProductDescription>
        <img alt="Logo do Tagnos" src={tagnosLogo} />

        <p>
          O tagnos Educação é uma ferramenta de gestão escolar, que de forma
          integrada, atende vários setores da estruturas de redes de ensino a
          escolas particulares. Conheça mais sobre essa incrível ferramenta que
          vem ajudando milhares de profissionais.
        </p>
      </ProductDescription>

      <Cards />
    </Container>
  )
}
