import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(200px, 1fr));
  gap: 30px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding: 20px 60px;
  margin: 0 auto;

  @media (max-width: 1420px) {
    grid-template-columns: repeat(4, minmax(200px, 1fr));
  }

  @media (max-width: 1170px) {
    grid-template-columns: repeat(3, minmax(200px, 1fr));
  }

  @media (max-width: 927px) {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }

  @media (max-width: 577px) {
    grid-template-columns: 1fr;
  }
`
