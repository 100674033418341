import React from 'react'

import Card from './Card'

import secretaria from 'src/assets/img/Products/Cards/secretaria.png'
import professor from 'src/assets/img/Products/Cards/professor.png'
import coordenacao from 'src/assets/img/Products/Cards/coordenacao.png'
import gestao from 'src/assets/img/Products/Cards/gestao.png'
import educacao_especial from 'src/assets/img/Products/Cards/educacao_especial.png'
import transporte from 'src/assets/img/Products/Cards/transporte.png'
import biblioteca from 'src/assets/img/Products/Cards/biblioteca.png'
import tecnologia from 'src/assets/img/Products/Cards/tecnologia.png'
import patrimonio from 'src/assets/img/Products/Cards/patrimonio.png'
import merenda_escolar from 'src/assets/img/Products/Cards/merenda_escolar.png'
import estoque from 'src/assets/img/Products/Cards/estoque.png'
import financeiro from 'src/assets/img/Products/Cards/financeiro.png'

import { Container } from './styles'

const Cards = () => {
  return (
    <Container>
      <Card icon={secretaria} title="secretaria">
        <ul>
          <li>a</li>
          <li>b</li>
          <li>c</li>
          <li>d</li>
          <li>e</li>
        </ul>
      </Card>

      <Card icon={professor} title="professor">
        <ul>
          <li>a</li>
          <li>b</li>
          <li>c</li>
          <li>d</li>
          <li>e</li>
        </ul>
      </Card>

      <Card icon={coordenacao} title="coordenação">
        <ul>
          <li>a</li>
          <li>b</li>
          <li>c</li>
          <li>d</li>
          <li>e</li>
        </ul>
      </Card>

      <Card icon={gestao} title="gestão">
        <ul>
          <li>a</li>
          <li>b</li>
          <li>c</li>
          <li>d</li>
          <li>e</li>
        </ul>
      </Card>

      <Card icon={educacao_especial} title="educação especial">
        <ul>
          <li>a</li>
          <li>b</li>
          <li>c</li>
          <li>d</li>
          <li>e</li>
        </ul>
      </Card>

      <Card icon={transporte} title="transporte">
        <ul>
          <li>a</li>
          <li>b</li>
          <li>c</li>
          <li>d</li>
          <li>e</li>
        </ul>
      </Card>

      <Card icon={biblioteca} title="biblioteca">
        <ul>
          <li>a</li>
          <li>b</li>
          <li>c</li>
          <li>d</li>
          <li>e</li>
        </ul>
      </Card>

      <Card icon={tecnologia} title="tecnologia">
        <ul>
          <li>a</li>
          <li>b</li>
          <li>c</li>
          <li>d</li>
          <li>e</li>
        </ul>
      </Card>

      <Card icon={patrimonio} title="patrimônio">
        <ul>
          <li>a</li>
          <li>b</li>
          <li>c</li>
          <li>d</li>
          <li>e</li>
        </ul>
      </Card>

      <Card icon={merenda_escolar} title="merenda escolar">
        <ul>
          <li>a</li>
          <li>b</li>
          <li>c</li>
          <li>d</li>
          <li>e</li>
        </ul>
      </Card>

      <Card icon={estoque} title="estoque">
        <ul>
          <li>a</li>
          <li>b</li>
          <li>c</li>
          <li>d</li>
          <li>e</li>
        </ul>
      </Card>

      <Card icon={financeiro} title="financeiro">
        <ul>
          <li>a</li>
          <li>b</li>
          <li>c</li>
          <li>d</li>
          <li>e</li>
        </ul>
      </Card>
    </Container>
  )
}

export default Cards
