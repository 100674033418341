import styled from 'styled-components'

export const Container = styled.div`
  background-color: #e7ac36;
  width: 250px;
  min-height: 450px;
  border-radius: 40px;
  perspective: 1000px;
  cursor: pointer;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;

  &:hover {
    box-shadow: 0 5px 19px rgba(0, 0, 0, 0.3);
    transform: translateY(-10px);
  }

  &:active {
    transition: transform 0.2s;
    transform: scale(0.99);
  }

  .content {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.4s ease;
    transform-style: preserve-3d;
    border-radius: 40px;
  }

  .flipped.content {
    transform: rotateY(180deg);
  }

  .front,
  .back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    border-radius: 40px;
  }

  .click {
    width: 100px;
    height: 100px;

    margin: 20px 0 40px 0;

    pointer-events: none;
  }

  .front {
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
      width: 100px;
      margin-bottom: 40px;
      pointer-events: none;
    }

    span {
      text-transform: uppercase;
      font-family: 'Gilroy-BlackItalic';
      font-size: 20px;
      letter-spacing: 1px;
      max-width: 190px;
      text-align: center;
    }
  }

  .back {
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 30px;

    .back-icon {
      width: 40px;
      margin-bottom: 15px;
      pointer-events: none;
    }

    .children {
      padding: 0 20px;
      ul {
        li {
          font-family: 'Gilroy-Regular';
          list-style: initial;
        }

        li:nth-child(odd) {
          font-family: 'Gilroy-Black';
        }
      }
    }
  }
`
